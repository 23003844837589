@import url(https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin-ext);
.dashboard-header-container {
  display: flex;
  height: 84px;
  background-color: #FFFFFF;
  padding: 0 20px; }
  .dashboard-header-container .dashboard-header-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto; }
    .dashboard-header-container .dashboard-header-content-container .language-selector-container {
      display: flex; }
      .dashboard-header-container .dashboard-header-content-container .language-selector-container .language {
        font-size: 14px;
        color: #000000;
        padding-left: 8px;
        cursor: pointer; }
        .dashboard-header-container .dashboard-header-content-container .language-selector-container .language.selected {
          color: #2049FC; }
        .dashboard-header-container .dashboard-header-content-container .language-selector-container .language:focus {
          outline: none; }

.page-home {
  display: flex;
  flex-direction: column; }
  .page-home .content-container {
    padding-top: 30px;
    display: flex;
    justify-content: center; }
    @media (min-width: 768px) {
      .page-home .content-container {
        padding-top: 40px; } }
    @media (min-width: 992px) {
      .page-home .content-container {
        padding-top: 100px; } }
    .page-home .content-container .container {
      max-width: 560px; }
  .page-home .ant-row-flex {
    align-items: stretch; }
  @media (max-width: 767px) {
    .page-home .col-right {
      display: none; } }
  .page-home .col-buttons {
    margin-top: 60px; }
    @media (min-width: 992px) {
      .page-home .col-buttons {
        margin-top: 40px; } }
  .page-home .home-info-box {
    position: relative; }
    @media (max-width: 767px) {
      .page-home .home-info-box {
        padding: 0 10px; } }
    @media (min-width: 768px) {
      .page-home .home-info-box {
        left: -25px; } }
    @media (min-width: 768px) {
      .page-home .home-info-box ol {
        margin-top: 20px; } }
    .page-home .home-info-box ol li {
      position: relative;
      font-size: 14px;
      line-height: 16px;
      padding-left: 20px;
      counter-increment: step-counter; }
      @media (min-width: 576px) {
        .page-home .home-info-box ol li {
          font-size: 16px;
          line-height: 20px; } }
      .page-home .home-info-box ol li + li {
        margin-top: 20px; }
      .page-home .home-info-box ol li::before {
        position: absolute;
        top: 0;
        left: 0; }
  .page-home .home-img-box img {
    width: 100%;
    display: block; }
  .page-home .button-wrap {
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
    display: flex;
    justify-content: center; }
    .page-home .button-wrap + .button-wrap {
      margin-top: 50px; }
    .page-home .button-wrap .ant-btn {
      width: 100%;
      max-width: 140px;
      height: 50px;
      font-size: 16px;
      line-height: 19px; }
      @media (min-width: 576px) {
        .page-home .button-wrap .ant-btn {
          max-width: 150px;
          height: 58px; } }
      @media (min-width: 992px) {
        .page-home .button-wrap .ant-btn {
          height: 42px;
          font-size: 14px;
          line-height: 16px; } }
      .page-home .button-wrap .ant-btn + .ant-btn {
        margin-left: 40px; }
        @media (min-width: 992px) {
          .page-home .button-wrap .ant-btn + .ant-btn {
            margin-left: 20px; } }
      .page-home .button-wrap .ant-btn.bordered {
        color: #2049FC !important;
        border-color: #2049FC !important; }
    .page-home .button-wrap .ant-btn-primary {
      max-width: 200px;
      color: #FFFFFF;
      background-color: #2049FC; }
      @media (min-width: 576px) {
        .page-home .button-wrap .ant-btn-primary {
          height: 56px; } }
      @media (min-width: 992px) {
        .page-home .button-wrap .ant-btn-primary {
          height: 40px; } }

.ant-btn.add-size {
  text-transform: uppercase; }

.stepper .ant-timeline-item-head {
  background-color: #2049FC;
  border-color: #2049FC;
  color: #2049FC; }

.stepper .ant-timeline-item-tail {
  border-color: #2049FC; }

.stepper .ant-timeline-item-content {
  padding: 0 0 12px 23px;
  font-size: 12px;
  position: relative;
  top: -3px;
  margin: 0; }
  .stepper .ant-timeline-item-content .stepper-content-title {
    font-size: 14px;
    line-height: 16px;
    color: #2B3950;
    border: none;
    outline: none; }
  .stepper .ant-timeline-item-content .stepper-component {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 38px;
    margin-top: 10px;
    padding-left: 20px;
    border-bottom: 1px solid #E6E6E6; }
    .stepper .ant-timeline-item-content .stepper-component + .stepper-component {
      margin-top: 0; }
    .stepper .ant-timeline-item-content .stepper-component:last-child {
      border-bottom: none; }
    .stepper .ant-timeline-item-content .stepper-component:before {
      position: absolute;
      top: 15px;
      left: -23px;
      content: "";
      width: 10px;
      height: 10px;
      border: 1px solid #2049FC;
      background-color: #2049FC;
      border-radius: 100%;
      pointer-events: none;
      -webkit-transition: background-color .2s ease;
      transition: background-color .2s ease; }
    .stepper .ant-timeline-item-content .stepper-component.not-filled:before {
      background-color: #FFFFFF; }
    .stepper .ant-timeline-item-content .stepper-component-title {
      position: relative;
      top: 1px;
      width: 90px;
      margin-right: 15px;
      font-size: 8px;
      line-height: 9px;
      color: #B5BCC5;
      text-align: right;
      text-transform: uppercase; }
    .stepper .ant-timeline-item-content .stepper-component-content {
      display: flex;
      align-items: center;
      text-align: left; }
      .stepper .ant-timeline-item-content .stepper-component-content span {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        color: #2B394F;
        -webkit-transition: color .2s ease;
        transition: color .2s ease; }
        .stepper .ant-timeline-item-content .stepper-component-content span.text-ral {
          margin-right: 4px;
          font-weight: 500;
          color: #969FAB;
          text-transform: uppercase; }
    .stepper .ant-timeline-item-content .stepper-component-box {
      margin-right: 10px;
      width: 15px;
      height: 15px;
      border-radius: 2px;
      box-shadow: 0 1px 3px 0 #CBD0D4; }
      .stepper .ant-timeline-item-content .stepper-component-box + span {
        text-transform: uppercase; }
    .stepper .ant-timeline-item-content .stepper-component.not-filled .stepper-component-title {
      color: #B5BCC5 !important; }
    .stepper .ant-timeline-item-content .stepper-component.not-filled .stepper-component-content span {
      color: #B5BCC5 !important; }
    .stepper .ant-timeline-item-content .stepper-component.not-filled .stepper-component-box {
      background-color: #FFFFFF !important; }
    .stepper .ant-timeline-item-content .stepper-component.remove-dot:before {
      display: none; }

.component-spinner {
  display: flex;
  justify-content: center;
  margin: 40px; }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); } }

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@-webkit-keyframes jello {
  11.1% {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes jello {
  11.1% {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); } }

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); } }

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); } }

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); } }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); } }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); } }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; } }

@-webkit-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@-webkit-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@-webkit-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@-webkit-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@-webkit-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@-webkit-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@-webkit-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  40% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    transform: translateY(700px); } }

@keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  40% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    transform: translateY(700px); } }

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes zoomInDown {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@keyframes zoomInDown {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@-webkit-keyframes zoomInLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@keyframes zoomInLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@-webkit-keyframes zoomInRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@keyframes zoomInRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@-webkit-keyframes zoomInUp {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@keyframes zoomInUp {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@-webkit-keyframes zoomOutDown {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }

@keyframes zoomOutDown {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@-webkit-keyframes zoomOutUp {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }

@keyframes zoomOutUp {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }

.type-selector {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-iteration-count: 1s;
  animation-iteration-count: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important;
  padding: 0 2px;
  margin-bottom: 20px;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease; }
  .type-selector.inactive {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
    -webkit-animation-iteration-count: 1s;
    animation-iteration-count: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0.3 !important;
    pointer-events: none !important; }
  .type-selector-choices {
    display: flex;
    flex-wrap: wrap; }
    .type-selector-choices .type-selector-button {
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin: 5px 0;
      margin-right: 5px;
      width: auto;
      height: auto;
      min-width: 120px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #FFFFFF;
      color: #2B3950;
      cursor: pointer;
      border: 1px solid #FFFFFF;
      -webkit-transition: all 1s ease;
      transition: all 1s ease; }
      .type-selector-choices .type-selector-button.wide {
        min-width: 120px; }
      .type-selector-choices .type-selector-button.with-icon .icon {
        font-size: 40px;
        line-height: 40px; }
      .type-selector-choices .type-selector-button.selected {
        color: #2049FC;
        border-color: #2049FC;
        -webkit-transition: all 1s ease;
        transition: all 1s ease; }
    .type-selector-choices .button-selector {
      box-sizing: border-box;
      height: 120px;
      width: 53px;
      margin: 5px 10px 10px 0;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      background-color: #FFFFFF !important;
      background-position: center center !important;
      background-size: contain !important;
      background-repeat: no-repeat !important; }
      .type-selector-choices .button-selector.selected {
        color: #2049FC;
        border-color: #2049FC;
        -webkit-transition: all 1s ease;
        transition: all 1s ease; }
  .type-selector .type-selector-choices {
    max-height: 320px;
    overflow: auto; }
  .type-selector .ant-btn:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f7f7f7 !important;
    border-color: #d9d9d9 !important;
    cursor: no-drop; }

.configurator-block-title {
  text-transform: uppercase; }

.color-palette .color-palette-colors {
  display: flex;
  flex-wrap: wrap;
  max-height: 320px;
  overflow: auto; }
  .color-palette .color-palette-colors .palette-color {
    width: 30px;
    min-width: 30px;
    padding: 0;
    text-align: center;
    width: auto;
    height: auto;
    margin-right: 22px;
    margin-bottom: 5px;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer; }
    .color-palette .color-palette-colors .palette-color .palette-color-preview {
      height: 30px;
      width: 30px;
      border-radius: 4px;
      background-color: #CBD0D4;
      border: 1px solid transparent;
      box-shadow: 0 2px 5px 0 #CBD0D4;
      margin-bottom: 5px;
      -webkit-transition: all 1s ease;
      transition: all 1s ease; }
      .color-palette .color-palette-colors .palette-color .palette-color-preview.selected {
        border-color: #2049FC;
        -webkit-transition: all 1s ease;
        transition: all 1s ease; }
    .color-palette .color-palette-colors .palette-color .palette-color-rar-text,
    .color-palette .color-palette-colors .palette-color .palette-color-rar-code {
      color: #969FAB;
      font-family: Roboto;
      font-size: 8px;
      line-height: 9px;
      text-align: center; }
    .color-palette .color-palette-colors .palette-color .palette-color-rar-code {
      color: #2B3950;
      font-weight: 500; }

.pattern-palette {
  display: flex;
  flex-wrap: wrap;
  max-height: 400px;
  overflow: auto; }

.page-configurator {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .page-configurator .content-container {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding-top: 30px;
    display: flex;
    flex-direction: row; }
    .page-configurator .content-container .configurator-container {
      padding: 20px;
      flex: 1 1; }
      .page-configurator .content-container .configurator-container .configurator-container-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0; }
        .page-configurator .content-container .configurator-container .configurator-container-header .step-title {
          font-size: 24px;
          padding: 0;
          border: none;
          line-height: 1;
          color: #2b3950;
          background-color: transparent;
          background-color: initial; }
        .page-configurator .content-container .configurator-container .configurator-container-header .step-action {
          min-height: 36px; }
          .page-configurator .content-container .configurator-container .configurator-container-header .step-action .btn-next {
            margin-left: 15px;
            font-size: 14px;
            color: #fff;
            line-height: 16px;
            background-color: #2049fc;
            min-width: 150px;
            height: 36px;
            border: none; }
      .page-configurator .content-container .configurator-container .configurator-container-body {
        display: flex; }
        .page-configurator .content-container .configurator-container .configurator-container-body .panel {
          flex: 1 1;
          padding: 0 20px; }
    .page-configurator .content-container .sidebar {
      position: relative;
      width: 265px;
      min-width: 265px;
      min-height: 586px;
      height: 100%;
      padding: 20px;
      background-color: #FFFFFF;
      align-self: flex-start; }
      @media (max-width: 991px) {
        .page-configurator .content-container .sidebar {
          display: flex;
          align-items: center;
          flex-direction: column;
          overflow-y: auto; } }
      @media (min-width: 992px) {
        .page-configurator .content-container .sidebar {
          height: auto; } }
      .page-configurator .content-container .sidebar-header {
        position: relative;
        margin-bottom: 20px; }
        @media (max-width: 991px) {
          .page-configurator .content-container .sidebar-header {
            width: 100%;
            max-width: 350px;
            z-index: 20; } }
        .page-configurator .content-container .sidebar-header .button-wrap {
          position: relative;
          display: flex;
          justify-content: center;
          margin-bottom: 10px; }
          @media (max-width: 991px) {
            .page-configurator .content-container .sidebar-header .button-wrap {
              position: fixed;
              bottom: 0;
              left: 0;
              width: 100%;
              margin-bottom: 0; } }
          .page-configurator .content-container .sidebar-header .button-wrap .btn {
            padding: 5px;
            height: 40px; }
          .page-configurator .content-container .sidebar-header .button-wrap .btn-contiue-later {
            font-size: 14px;
            line-height: 16px; }
            @media (max-width: 991px) {
              .page-configurator .content-container .sidebar-header .button-wrap .btn-contiue-later {
                width: 100%;
                height: 50px;
                text-align: center;
                border-radius: 0;
                color: #FFFFFF;
                background-color: #2049FC; } }
            @media (min-width: 992px) {
              .page-configurator .content-container .sidebar-header .button-wrap .btn-contiue-later {
                width: 100%;
                max-width: 150px;
                color: #2049FC;
                border-color: #2049FC;
                box-shadow: 0 2px 5px 0 #CBD0D4; } }
          .page-configurator .content-container .sidebar-header .button-wrap .btn-delete {
            position: absolute;
            right: -20px;
            width: 40px;
            font-size: 22px;
            color: #2B3950;
            border: none; }
            .page-configurator .content-container .sidebar-header .button-wrap .btn-delete:hover {
              color: #2049FC; }
            @media (max-width: 991px) {
              .page-configurator .content-container .sidebar-header .button-wrap .btn-delete {
                display: none; } }
        .page-configurator .content-container .sidebar-header .door-name {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0 -8px;
          overflow: hidden; }
          .page-configurator .content-container .sidebar-header .door-name .input-door-name {
            height: 30px;
            font-size: 14px;
            color: #2B3950;
            font-weight: 700;
            padding-right: 28px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            border-color: transparent;
            pointer-events: none; }
          .page-configurator .content-container .sidebar-header .door-name .btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
            color: #2049FC;
            font-size: 12px;
            font-weight: 300;
            padding: 2px 5px;
            border: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            box-shadow: none !important;
            -webkit-transition: all .1s ease;
            transition: all .1s ease;
            -webkit-transition-property: opacity, -webkit-transform;
            transition-property: opacity, -webkit-transform;
            transition-property: opacity, transform;
            transition-property: opacity, transform, -webkit-transform; }
            .page-configurator .content-container .sidebar-header .door-name .btn i {
              line-height: 1;
              font-size: 12px; }
          .page-configurator .content-container .sidebar-header .door-name .btn-edit {
            opacity: 1;
            pointer-events: all;
            -webkit-transform: translate(6px, 0);
                    transform: translate(6px, 0); }
          .page-configurator .content-container .sidebar-header .door-name .btn-change {
            opacity: 0;
            pointer-events: none;
            -webkit-transform: translate(6px, 0);
                    transform: translate(6px, 0); }
          .page-configurator .content-container .sidebar-header .door-name.active .input-door-name {
            pointer-events: all;
            border-color: #2049FC; }
          .page-configurator .content-container .sidebar-header .door-name.active .btn-edit {
            opacity: 0;
            pointer-events: none;
            -webkit-transform: translate(-6px, 0);
                    transform: translate(-6px, 0); }
          .page-configurator .content-container .sidebar-header .door-name.active .btn-change {
            opacity: 1;
            pointer-events: all;
            -webkit-transform: translate(0, 0);
                    transform: translate(0, 0); }
      .page-configurator .content-container .sidebar .stepper.collapsed .ant-timeline-item:not(.current-step) .ant-timeline-item-head {
        background-color: white !important; }
      .page-configurator .content-container .sidebar .stepper.collapsed .ant-timeline-item:not(.current-step) .stepper-component {
        display: none !important; }
      .page-configurator .content-container .sidebar .stepper.collapsed .ant-timeline-item:not(.current-step) .stepper-content-title {
        cursor: default; }
      .page-configurator .content-container .sidebar .stepper.collapsed .ant-timeline-item.passed-step .ant-timeline-item-head {
        background-color: #2049FC !important; }
      .page-configurator .content-container .sidebar .stepper.collapsed .ant-timeline-item.passed-step .stepper-content-title, .page-configurator .content-container .sidebar .stepper.collapsed .ant-timeline-item.current-step .stepper-content-title {
        cursor: pointer; }
      @media (max-width: 991px) {
        .page-configurator .content-container .sidebar .stepper {
          width: 100%;
          max-width: 350px;
          z-index: 10; } }
      .page-configurator .content-container .sidebar .stepper .stepper-component {
        padding-left: 0; }
      .page-configurator .content-container .sidebar .stepper .stepper-component-content {
        width: 90px; }


@charset "UTF-8";

@font-face {
  font-family: "fullco";
  src: url(/static/media/fullco.547cc11f.woff);
  src: url(/static/media/fullco.9d7ee281.eot) format("embedded-opentype"),
    url(/static/media/fullco.9d7ee281.eot?#iefix) format("embedded-opentype"),
    url(/static/media/fullco.fe45ecf8.ttf) format("truetype"),
    url("[object Module]#untitled-font-2") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "fullco" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fullco" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-backnarrow:before {
  content: "\62";
}
.icon-uncheckbox:before {
  content: "\63";
}
.icon-iconmenu:before {
  content: "\64";
}
.icon-haloszoba:before {
  content: "\65";
}
.icon-gyerekszoba:before {
  content: "\66";
}
.icon-remove:before {
  content: "\67";
}
.icon-search:before {
  content: "\68";
}
.icon-pipa:before {
  content: "\69";
}
.icon-expand:before {
  content: "\6a";
}
.icon-ovalsmallstroke:before {
  content: "\6b";
}
.icon-furdoszoba:before {
  content: "\6c";
}
.icon-duplicatedoor:before {
  content: "\6d";
}
.icon-ovalbig:before {
  content: "\6e";
}
.icon-opendoor:before {
  content: "\6f";
}
.icon-downloadofferrequest:before {
  content: "\70";
}
.icon-delete:before {
  content: "\71";
}
.icon-narrowright:before {
  content: "\72";
}
.icon-nappali:before {
  content: "\73";
}
.icon-colorpicker:before {
  content: "\74";
}
.icon-close:before {
  content: "\75";
}
.icon-mosaicview:before {
  content: "\76";
}
.icon-listview:before {
  content: "\77";
}
.icon-ckecbox-selected:before {
  content: "\78";
}
.icon-iconprofilemenu:before {
  content: "\7a";
}
.icon-changedoorview:before {
  content: "\61";
}
.icon-iroda:before {
  content: "\79";
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  background-color: #E2F4F8 !important; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.ant-collapse-content {
  background-color: #E2F4F8; }

button {
  background-color: transparent;
  cursor: pointer; }

.canvas-container {
  pointer-events: none; }

