@import 'src/styles/colors';

.stepper {
  .ant-timeline-item-head {
    background-color: $INK_BLUE_LIGHT;
    border-color: $INK_BLUE_LIGHT;
    color: $INK_BLUE_LIGHT;
  }

  .ant-timeline-item-tail {
    border-color: $INK_BLUE_LIGHT;
  }

  .ant-timeline-item-content {
    padding: 0 0 12px 23px;
    font-size: 12px;
    position: relative;
    top: -3px;
    margin: 0;

    .stepper-content-title {
      font-size: 14px;
      line-height: 16px;
      color: $ASPHALT;
      border: none;
      outline: none;
    }

    .stepper-component {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 38px;
      margin-top: 10px;
      padding-left: 20px;
      border-bottom: 1px solid #E6E6E6;

      & + .stepper-component {
        margin-top: 0;
      }

      &:last-child {
        border-bottom: none;
      }

      &:before {
        position: absolute;
        top: 15px;
        left: -23px;
        content: "";
        width: 10px;
        height: 10px;
        border: 1px solid $INK_BLUE_LIGHT;
        background-color: $INK_BLUE_LIGHT;
        border-radius: 100%;
        pointer-events: none;
        transition: background-color .2s ease;
      }

      &.not-filled {
        &:before {
          background-color: #FFFFFF;
        }
      }

      &-title {
        position: relative;
        top: 1px;
        width: 90px;
        margin-right: 15px;
        font-size: 8px;
        line-height: 9px;
        color: #B5BCC5;
        text-align: right;
        text-transform: uppercase;
      }

      &-content {
        display: flex;
        align-items: center;
        text-align: left;

        span {
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          color: $TITLE;
          transition: color .2s ease;

          &.text-ral {
            margin-right: 4px;
            font-weight: 500;
            color: #969FAB;
            text-transform: uppercase;
          }
        }
      }

      &-box {
        margin-right: 10px;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        box-shadow: 0 1px 3px 0 $SHADOW;

        & + span {
          text-transform: uppercase;
        }
      }

      &.not-filled {
        .stepper-component-title {
          color: #B5BCC5 !important;
        }

        .stepper-component-content span{
          color: #B5BCC5 !important;
        }

        .stepper-component-box {
          background-color: #FFFFFF !important;
        }
      }

      &.remove-dot {
        &:before {
          display: none;
        }
      }
    }
  }
}
