@import '../../styles/breakpoints';
@import 'src/styles/colors';

.page-configurator {
  display: flex;
  flex-direction: column;
  width: 100%;

  .content-container {
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    padding-top: 30px;
    display: flex;
    flex-direction: row;

    .configurator-container {
      padding: 20px;
      flex: 1;

      .configurator-container-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
        
        .step-title {
          font-size: 24px;
          padding: 0;
          border: none;
          line-height: 1;
          color: #2b3950;
          background-color: initial;
        }
        
        .step-action {
          min-height: 36px;

          .btn-next {
            margin-left: 15px;
            font-size: 14px;
            color: #fff;
            line-height: 16px;
            background-color: #2049fc;
            min-width: 150px;
            height: 36px;
            border: none;
          }
        }
      }
    
      .configurator-container-body {
        display: flex;
        
        .panel {
          flex: 1;
          padding: 0 20px;
        }
      }
    }

    .sidebar {
      position: relative;
      width: 265px;
      min-width: 265px;
      min-height: 586px;
      height: 100%;
      padding: 20px;
      background-color: #FFFFFF;
      align-self: flex-start;

      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
      }

      @include media-breakpoint-up(lg) {
        height: auto;
      }

      &-header {
        position: relative;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
          width: 100%;
          max-width: 350px;
          z-index: 20;
        }

        .button-wrap {
          position: relative;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;

          @include media-breakpoint-down(md) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            margin-bottom: 0;
          }

          .btn {
            padding: 5px;
            height: 40px;
          }

          .btn-contiue-later {
            font-size: 14px;
            line-height: 16px;

            @include media-breakpoint-down(md) {
              width: 100%;
              height: 50px;
              text-align: center;
              border-radius: 0;
              color: #FFFFFF;
              background-color: $INK_BLUE_LIGHT;
            }

            @include media-breakpoint-up(lg) {
              width: 100%;
              max-width: 150px;
              color: $INK_BLUE_LIGHT;
              border-color: $INK_BLUE_LIGHT;
              box-shadow: 0 2px 5px 0 #CBD0D4;
            }
          }

          .btn-delete {
            position: absolute;
            right: -20px;
            width: 40px;
            font-size: 22px;
            color: $ASPHALT;
            border: none;

            &:hover {
              color: $INK_BLUE_LIGHT;
            }

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }

        .door-name {
          position: relative;
          display: flex;
          align-items: center;
          margin: 0 -8px;
          overflow: hidden;

          .input-door-name {
            height: 30px;
            font-size: 14px;
            color: $ASPHALT;
            font-weight: 700;
            padding-right: 28px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            border-color: transparent;
            pointer-events: none;
          }

          .btn{
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
            height: 30px;
            color: $INK_BLUE_LIGHT;
            font-size: 12px;
            font-weight: 300;
            padding: 2px 5px;
            border: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            box-shadow: none !important;
            transition: all .1s ease;
            transition-property: opacity, transform;

            i {
              line-height: 1;
              font-size: 12px;
            }
          }

          .btn-edit {
            opacity: 1;
            pointer-events: all;
            transform: translate(6px, 0);
          }

          .btn-change {
            opacity: 0;
            pointer-events: none;
            transform: translate(6px, 0);
          }

          &.active {
            .input-door-name {
              pointer-events: all;
              border-color: $INK_BLUE_LIGHT;
            }
            .btn-edit {
              opacity: 0;
              pointer-events: none;
              transform: translate(-6px, 0);
            }
            .btn-change {
              opacity: 1;
              pointer-events: all;
              transform: translate(0, 0);
            }
          }
        }
      }
      
      .stepper.collapsed {
        .ant-timeline-item {
          &:not(.current-step) {
            .ant-timeline-item-head {
              background-color: white !important;
            }

            .stepper-component {
              display: none !important;
            }

            .stepper-content-title {
              cursor: default;
            }
          }

          &.passed-step {
            .ant-timeline-item-head {
              background-color: $INK_BLUE_LIGHT !important;
            }
          }

          &.passed-step,
          &.current-step {
            .stepper-content-title {
              cursor: pointer;
            }
          }
        }
      }

      .stepper {
        @include media-breakpoint-down(md) {
          width: 100%;
          max-width: 350px;
          z-index: 10;
        }

        .stepper-component {
          padding-left: 0;
        }

        .stepper-component-content {
          width: 90px;
        }
      }
    }
  }
}
