@charset "UTF-8";

@font-face {
  font-family: "fullco";
  src: url("./fullco.woff");
  src: url("./fullco.eot") format("embedded-opentype"),
    url("./fullco.eot?#iefix") format("embedded-opentype"),
    url("./fullco.ttf") format("truetype"),
    url("./fullco.svg#untitled-font-2") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "fullco" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fullco" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-backnarrow:before {
  content: "\62";
}
.icon-uncheckbox:before {
  content: "\63";
}
.icon-iconmenu:before {
  content: "\64";
}
.icon-haloszoba:before {
  content: "\65";
}
.icon-gyerekszoba:before {
  content: "\66";
}
.icon-remove:before {
  content: "\67";
}
.icon-search:before {
  content: "\68";
}
.icon-pipa:before {
  content: "\69";
}
.icon-expand:before {
  content: "\6a";
}
.icon-ovalsmallstroke:before {
  content: "\6b";
}
.icon-furdoszoba:before {
  content: "\6c";
}
.icon-duplicatedoor:before {
  content: "\6d";
}
.icon-ovalbig:before {
  content: "\6e";
}
.icon-opendoor:before {
  content: "\6f";
}
.icon-downloadofferrequest:before {
  content: "\70";
}
.icon-delete:before {
  content: "\71";
}
.icon-narrowright:before {
  content: "\72";
}
.icon-nappali:before {
  content: "\73";
}
.icon-colorpicker:before {
  content: "\74";
}
.icon-close:before {
  content: "\75";
}
.icon-mosaicview:before {
  content: "\76";
}
.icon-listview:before {
  content: "\77";
}
.icon-ckecbox-selected:before {
  content: "\78";
}
.icon-iconprofilemenu:before {
  content: "\7a";
}
.icon-changedoorview:before {
  content: "\61";
}
.icon-iroda:before {
  content: "\79";
}
