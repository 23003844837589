@import 'src/styles/breakpoints';
@import 'src/styles/colors';

.dashboard-header-container {
  display: flex;
  height: 84px;
  background-color: #FFFFFF;
  padding: 0 20px;

  .dashboard-header-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;

    .language-selector-container {
      display: flex;
      
      .language {
        font-size: 14px;
        color: #000000;
        padding-left: 8px;
        cursor: pointer;
        
        &.selected {
          color: $INK_BLUE_LIGHT;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
