@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin-ext');
@import './styles/font/styles.css';

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  background-color: #E2F4F8 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-collapse-content {
  background-color: #E2F4F8;
}

button {
  background-color: transparent;
  cursor: pointer;
}

.canvas-container {
  pointer-events: none;
}

