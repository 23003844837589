@import 'src/styles/colors';
$PREVIEW_WIDTH: 30px;

.color-palette {
  .color-palette-colors {
    display: flex;
    flex-wrap: wrap;
    max-height: 320px;
    overflow: auto;

    .palette-color {
      width: $PREVIEW_WIDTH;
      min-width: $PREVIEW_WIDTH;
      padding: 0;
      text-align: center;
      width: auto;
      height: auto;
      margin-right: 22px;
      margin-bottom: 5px;
      background-color: transparent;
      border-color: transparent;
      cursor: pointer;


      .palette-color-preview {
        height: $PREVIEW_WIDTH;
        width: $PREVIEW_WIDTH;
        border-radius: 4px;
        background-color: $SHADOW;
        border: 1px solid transparent;
        box-shadow: 0 2px 5px 0 $SHADOW;
        margin-bottom: 5px;
        transition: all 1s ease;

        &.selected {
          border-color: $INK_BLUE_LIGHT;
          transition: all 1s ease;
        }
      }

      .palette-color-rar-text,
      .palette-color-rar-code {
        color: $COLOR_PREVIEW_TEXT;
        font-family: Roboto;
        font-size: 8px;
        line-height: 9px;
        text-align: center;
      }

      .palette-color-rar-code {
        color: $ASPHALT;
        font-weight: 500;
      }
    }
  }
}
