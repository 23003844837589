@import 'src/styles/colors';
@import 'src/styles/animations';

.type-selector {
  @include fadeInUp();

  animation-duration: 0.5s !important;
  padding: 0 2px;
  margin-bottom: 20px;
  transition: opacity .2s ease;

  &.inactive {
    @include fadeOutUp();
    opacity: 0.3 !important;
    pointer-events: none !important;
  }

  &-choices {
    display: flex;
    flex-wrap: wrap;

    .type-selector-button {
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin: 5px 0;
      margin-right: 5px;
      width: auto;
      height: auto;
      min-width: 120px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #FFFFFF;
      color: $ASPHALT;
      cursor: pointer;
      border: 1px solid #FFFFFF;
      transition: all 1s ease;

      &.wide {
        min-width: 120px;
      }

      &.with-icon {
        .icon {
          font-size: 40px;
          line-height: 40px;
        }
      }

      &.selected {
        color: $INK_BLUE_LIGHT;
        border-color: $INK_BLUE_LIGHT;
        transition: all 1s ease;
      }
    }

    .button-selector {
      box-sizing: border-box;
      height: 120px;
      width: 53px;
      margin: 5px 10px 10px 0;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      background-color: #FFFFFF !important;
      background-position: center center !important;
      background-size: contain !important;
      background-repeat: no-repeat !important;

      &.selected {
        color: $INK_BLUE_LIGHT;
        border-color: $INK_BLUE_LIGHT;
        transition: all 1s ease;
      }
    }
  }
  
  .type-selector-choices {
    max-height: 320px;
    overflow: auto;
  }

  .ant-btn:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f7f7f7 !important;
    border-color: #d9d9d9 !important;
    cursor: no-drop;
  }

}

.configurator-block-title {
  text-transform: uppercase;
}
