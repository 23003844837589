@import 'src/styles/breakpoints';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.page-home {
  display: flex;
  flex-direction: column;

  .content-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      padding-top: 40px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 100px;
    }

    .container {
      max-width: 560px;
    }
  }

  .ant-row-flex {
    align-items: stretch;
  }

  .col-right {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .col-buttons {
    margin-top: 60px;

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }
  }

  .home-info-box {
    position: relative;

    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }

    @include media-breakpoint-up(md) {
      left: -25px;
    }

    ol {
      @include media-breakpoint-up(md) {
        margin-top: 20px;
      }

      li {
        position: relative;
        font-size: 14px;
        line-height: 16px;
        padding-left: 20px;
        counter-increment: step-counter;

        @include media-breakpoint-up(sm) {
          font-size: 16px;
          line-height: 20px;
        }

        & + li {
          margin-top: 20px;
        }

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          // content: counter(step-counter) ".";
        }
      }
    }
  }

  .home-img-box {
    img {
      width: 100%;
      display: block;
    }
  }

  .button-wrap {
    width: 100%;
    max-width: 315px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    & + .button-wrap {
      margin-top: 50px;
    }

    .ant-btn {
      width: 100%;
      max-width: 140px;
      height: 50px;
      font-size: 16px;
      line-height: 19px;

      @include media-breakpoint-up(sm) {
        max-width: 150px;
        height: 58px;
      }

      @include media-breakpoint-up(lg) {
        height: 42px;
        font-size: 14px;
        line-height: 16px;
      }

      & + .ant-btn {
        margin-left: 40px;

        @include media-breakpoint-up(lg) {
          margin-left: 20px;
        }
      }

      &.bordered {
        color: $INK_BLUE_LIGHT !important;
        border-color: $INK_BLUE_LIGHT !important;
      }
    }

    .ant-btn-primary {
      max-width: 200px;
      color: #FFFFFF;
      background-color: $INK_BLUE_LIGHT;

      @include media-breakpoint-up(sm) {
        height: 56px;
      }

      @include media-breakpoint-up(lg) {
        height: 40px;
      }
    }
  }
}

.ant-btn {
  &.add-size {
    text-transform: uppercase;
  }
}
